import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgencyAssets,
  setAgencyAssets,
  setDefaultAgencyAssets,
} from "../store/actions/common";

export default function useLoadBrandAssets() {
  const selectedBrand = useSelector(
    (state: any) => state.adaccount.selectedBrand
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedBrand?.agencyId && selectedBrand?.id) {
      dispatch(
        getAgencyAssets(
          { agencyId: selectedBrand?.agencyId, brandId: selectedBrand?.id },
          (response: any, error: boolean) => {
            if (!error && response?.data?.data) {
              dispatch(setAgencyAssets(response?.data?.data));
            } else {
              dispatch(setDefaultAgencyAssets());
              console.log(error);
            }
          }
        )
      );
    }
  }, [selectedBrand]);
}
